// 引用两个插件
import WarrantComparisonhtml2canvas from 'html2canvas'; // 转为图片
import WarrantComparisonprintJS from 'print-js' // 打印
import disableScroll from 'disable-scroll';

export default {
    name: "WarrantComparison",
    created () {
        // localStorage.clear()
        // this.$$.navbarAnimation()
        this.$nextTick(function () {
            this.ariseCombobox('#comboboxUnderlying')

            if (this.$route.params.ricArr !== undefined) {
                $('#WarrantComparison').find('.optionsBox').hide()
                this.underlyingRicData = this.$route.params.ricArr.split(",")
                this.GetMarketData()
            } else {
                // 获取 underlying ric
                if (localStorage.getItem('comparisonRic') !== 'null') {
                    $('.comboboxUnderlyingBox').find('input').val(localStorage.getItem('comparisonName'))
                    this.GetRICListByUnderlying(localStorage.getItem('comparisonRic'))
                }
                this.GetUnderlyingList()
            }
        });
    },
    mounted () {
        this.$nextTick(function () {

        })
    },
    data () {
        return {
            // 列表内容数据
            tableContentData: [{
                namef: "Warrant Name",
                name: this.$t('WSe.WN'),
                data: [],
                visible: false
            },
            {
                namef: "Indicators",
                name: this.$t('WSe.In'),
                data: []
            },
            {
                namef: "Issuer",
                name: this.$t('WTs.Is'),
                data: [],
                visible: false
            },
            {
                namef: "Type",
                name: this.$t('hp.Ty'),
                data: [],
                visible: false
            },
            {
                namef: "Days to Last Trading Date",
                name: this.$t('WSe.DtLTD'),
                data: [],
                visible: false
            },
            {
                namef: "Last Trading Date",
                name: this.$t('WSe.ED'),
                data: [],
                visible: false
            },
            {
                namef: "Sensitivity",
                name: this.$t('int.Se'),
                data: [],
                visible: false,
                top: 65,
                left: -100,
            },
            {
                namef: "Effective Gearing",
                name: this.$t('int.EG'),
                data: [],
                visible: false,
                top: 65,
                left: -100,
            },
            {
                namef: "Theta (THB/days)",
                name: this.$t('int.Th') + ' (THB/' + this.$t('WSe.da') + ')',
                data: [],
                visible: false,
                top: 25,
                left: -100,
            },
            {
                namef: "Theta (%/days)",
                name: this.$t('int.Th') + ' (%/' + this.$t('WSe.da') + ')',
                data: [],
                visible: false,
                top: 45,
                left: -100,
            },
            {
                namef: "Theta (days)",
                name: this.$t('int.Th') + ' (' + this.$t('WSe.da') + ')',
                data: [],
                visible: false,
                top: 45,
                left: -100,
            },
            {
                namef: "Moneyness",
                name: this.$t('WSe.Mo'),
                data: [],
                visible: true,
                ifData: []
            },
            {
                namef: "Premium",
                name: this.$t('int.Pr'),
                data: [],
                visible: false,
                top: 85,
                left: -100,
            },
            {
                namef: "Delta",
                name: this.$t('int.De'),
                data: [],
                visible: false,
                top: 50,
                left: -100,
            },
            {
                namef: "Implied Volatility",
                name: this.$t('int.IV'),
                data: [],
                visible: false,
                top: 100,
                left: -100,
            },
            {
                namef: "Historical Volatility",
                name: this.$t('WSe.HV'),
                data: [],
                visible: false
            },
            {
                namef: "Exercise Price",
                name: this.$t('WSe.EP'),
                data: [],
                visible: false
            },
            {
                namef: "Exercise Ratio (DW:1UL)",
                name: this.$t('WSe.ER') + ' (DW:1UL)',
                data: [],
                visible: false
            },

            {
                namef: "Bid Price",
                name: this.$t('hp.BP'),
                data: [],
                visible: false
            },
            {
                namef: "Bid Change (%)",
                name: this.$t('WSe.BC') + ' (%)',
                data: [],
                visible: true
            },
            {
                namef: "Bid Volume ('000)",
                name: this.$t('WSe.BV') + ` ('000)`,
                data: [],
                visible: false
            },
            {
                namef: "Offer Price",
                name: this.$t('WSe.OP'),
                data: [],
                visible: false
            },
            // {
            //     namef: "Offer Change (%)",
            //     name: this.$t('WSe.OC') + ' (%)',
            //     data: [],
            //     visible: true
            // },
            {
                namef: "Offer Volume ('000)",
                name: this.$t('WSe.OV') + ` ('000)`,
                data: [],
                visible: false
            },
            {
                namef: "Traded Volume ('000)",
                name: this.$t('WSe.TV') + " ('000)",
                data: [],
                visible: false
            },
            ],
            conditionIcon: [
                require('../../../../../public/image/Daily_Highlight.png'),
                require('../../../../../public/image/More_Responsive.png'),
                require('../../../../../public/image/Low_Time_Decay.png'),
                require('../../../../../public/image/Low_Inventory.png'),
                require('../../../../../public/image/Near_Expiry.png'),
                require('../../../../../public/image/Low_DW_Price.png')
            ],
            conditionData: [{
                icon: require('../../../../../public/image/Daily_Highlight.png'),
                name: this.$t('int.DH'),
                hint: '<strong>' + this.$t('int.DH') + ':</strong> ' + this.$t('int.Stcitm')
            },
            {
                icon: require('../../../../../public/image/More_Responsive.png'),
                name: this.$t('int.MR'),
                hint: '<strong>' + this.$t('int.MR') + ': </strong> ' + this.$t('int.MSsmou')
            },
            {
                icon: require('../../../../../public/image/Low_Time_Decay.png'),
                name: this.$t('int.LTD'),
                hint: '<strong>' + this.$t('int.LTD') + ':</strong> ' + this.$t('int.MSmtd')
            },
            {
                icon: require('../../../../../public/image/Low_Inventory.png'),
                name: this.$t('int.LI'),
                hint: '<strong>' + this.$t('int.LI') + ':</strong> ' + this.$t('int.MScMa') 
            },
            {
                icon: require('../../../../../public/image/Near_Expiry.png'),
                name: this.$t('int.NE'),
                hint: '<strong>' + this.$t('int.NE') + ':</strong> ' + this.$t('int.Ddtdb') 
            },
            {
                icon: require('../../../../../public/image/Low_DW_Price.png'),
                name: this.$t('int.LDP'),
                hint: '<strong>' + this.$t('int.LDP') + ':</strong> ' + this.$t('int.DbbT') 
            }
            ],
            publishTime: '',
            // underlying
            underlyingData: [],
            // underlying ric
            underlyingRicData: [],
            // select underlying 的数据
            selectUnderlyingData: [],
            // select underlying input 的 text
            selectUnderlyingInputText: '',
            textArr: [],
            // select underlying下拉菜单的显示
            ifSelectUnderlying: false,
            // 重置提示的显示
            mouse1: false,
            mouse2: false,
            mouse3: false,
        };
    },
    computed: {

    },
    watch: {

    },
    methods: {
        // 获取 underlying ric
        GetUnderlyingList: function () {
            $.ajax({
                type: "GET",
                url: this.$$.mibb + "GetUnderlyingList",
                data: { token: "webkey", issuers: '' },
                dataType: "json",
                success: (data) => {
                    if (data.status === "SUCCESS") {

                        console.log(data);
                        this.underlyingData = data.underlying_name
                        if (localStorage.getItem('comparisonRic') == 'null') {
                            $('.comboboxUnderlyingBox').find('input').val(`${data.underlying_name[0].underlying_ticker} (${data.underlying_name[0].underlying_name})`)
                            localStorage.setItem("comparisonName", `${data.underlying_name[0].underlying_ticker} (${data.underlying_name[0].underlying_name})`)
                            this.GetRICListByUnderlying(data.underlying_name[0].underlying_name)
                        }

                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        // 获取 underlying 的数据
        GetRICListByUnderlying: function (underlying, vit) {
            let _this = this
            this.selectUnderlyingData = []
            $.ajax({
                type: "GET",
                url: this.$$.mibb + "GetRICListByUnderlying",
                data: { token: "webkey", issuers: '', underlying: underlying },
                dataType: "json",
                success: (data) => {
                    if (data.status === "SUCCESS") {
                        localStorage.setItem("comparisonRic", underlying)
                        $.each(data.ric, function (i, c) {
                            if (vit) {
                                if (i < 8) {
                                    _this.underlyingRicData.push(c.ric)
                                    _this.textArr.push(c.dsply_nmll)
                                    _this.selectUnderlyingData.push({
                                        name: c.dsply_nmll,
                                        value: c.ric,
                                        visible: true,
                                        disabled: false
                                    })
                                } else {
                                    _this.selectUnderlyingData.push({
                                        name: c.dsply_nmll,
                                        value: c.ric,
                                        visible: false,
                                        disabled: true
                                    })
                                }
                            } else {
                                _this.selectUnderlyingData.push({
                                    name: c.dsply_nmll,
                                    value: c.ric,
                                    visible: false,
                                    disabled: false
                                })
                            }
                        })
                        if (vit) {
                            this.selectUnderlyingInputText = this.textArr.toString()
                            this.GetMarketData()
                        }
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        /*获取当前ric数据*/
        GetMarketData: function () {
            let _this = this,
                arrIcon
      
            $.ajax({
                type: "GET",
                url: this.$$.mibb + "GetMarketData",
                data: { token: "webkey", ric: this.underlyingRicData.toString(), type: "warrantdata" },
                dataType: "json",
                success: (data) => {
                    if (data.status === "SUCCESS") {
                        $.each(this.tableContentData, function (i, c) {
                            c.data = []
                            if (i == 7) {
                                c.ifData = []
                            }
                        })
                        $.each(this.underlyingRicData, function (i, c) {
                            arrIcon = []
                            if (data[c].daily_highlights !== '0') {
                                arrIcon.push({
                                    img: _this.conditionIcon[0],
                                    name: '<strong>' + _this.$t('int.DH') + ':</strong> ' + _this.$t('int.Stcitm')
                                })
                            }
                            if (data[c].more_responsive !== '0') {
                                arrIcon.push({
                                    img: _this.conditionIcon[1],
                                    name: '<strong>' + _this.$t('int.MR') + ': </strong> ' + _this.$t('int.MSsmou')
                                })
                            }
                            if (data[c].low_time_decay !== '0') {
                                arrIcon.push({
                                    img: _this.conditionIcon[2],
                                    name: '<strong>' + _this.$t('int.LTD') + ':</strong> ' + _this.$t('int.MSmtd')
                                })
                            }
                            if (data[c].soldout !== '0') {
                                arrIcon.push({
                                    img: _this.conditionIcon[3],
                                    name: '<strong>' + _this.$t('int.LI') + ':</strong> ' + _this.$t('int.MScMa')
                                })
                            }
                            if (data[c].near_expiry !== '0') {
                                arrIcon.push({
                                    img: _this.conditionIcon[4],
                                    name: '<strong>' + _this.$t('int.NE') + ':</strong> ' + _this.$t('int.Ddtdb')
                                })
                            }
                            if (data[c].low_dw_price !== '0') {
                                arrIcon.push({
                                    img: _this.conditionIcon[5],
                                    name: '<strong>' + _this.$t('int.LDP') + ':</strong> ' + _this.$t('int.DbbT')
                                })
                            }
                            if (data[c].issuer_name == 'MST') {
                                _this.tableContentData[0].data.unshift([data[c].dsply_nmll, data[c].ric])
                                _this.tableContentData[1].data.unshift(arrIcon)
                                _this.tableContentData[2].data.unshift(data[c].issuer_name2)
                                _this.tableContentData[3].data.unshift(data[c].type)
                                _this.tableContentData[4].data.unshift(data[c].TimeToMaturity + ' ' + _this.$t("WSe.da"))
                                _this.tableContentData[5].data.unshift(data[c].LSTTRDDATE)
                                _this.tableContentData[6].data.unshift(_this.$$.formatNumber(data[c].sensitivity, 1, 1) > 1 ? _this.$$.formatNumber(data[c].sensitivity, 1, 1) + _this.$t("WTs.ticks") : _this.$$.formatNumber(data[c].sensitivity, 1, 1) + _this.$t("WTs.tick"))
                                _this.tableContentData[7].data.unshift(_this.$$.formatNumber(data[c].effective_gearing, 1, 1) + ' x')
                                _this.tableContentData[8].data.unshift(_this.$$.formatNumber(data[c].theta_days, 6, 1))
                                _this.tableContentData[9].data.unshift(_this.$$.formatNumber(data[c].theta_pct, 1, 1) + ' %')
                                _this.tableContentData[10].data.unshift(_this.$$.formatNumber(data[c].theta, 0, 1) > 1 ? _this.$$.formatNumber(data[c].theta, 0, 1) + ' ' + _this.$t("WSe.da") : _this.$$.formatNumber(data[c].theta, 0, 1) + ' ' + _this.$t("WSe.day"))
                                _this.tableContentData[11].data.unshift(`${_this.$$.formatNumber(data[c].moneyness_c.split('%')[0], 1, 0)}% ${data[c].moneyness_c.split('%')[1]}`)
                                _this.tableContentData[11].ifData.unshift(data[c].moneyness_c.indexOf('ITM') >= 0 ? true : false)
                                _this.tableContentData[12].data.unshift(_this.$$.formatNumber(data[c].premium, 1, 1) + ' %')
                                _this.tableContentData[13].data.unshift(_this.$$.formatNumber(data[c].delta, 1, 1) + ' %')
                                _this.tableContentData[14].data.unshift(_this.$$.formatNumber(data[c].implied_volatility, 1, 1) + ' %')
                                _this.tableContentData[15].data.unshift(_this.$$.formatNumber(data[c].underlying_hst_vol, 1, 1)+ ' %')
                                _this.tableContentData[16].data.unshift(data[c].underlying_curr + ' ' + _this.$$.kilobitNumber(_this.$$.formatNumber(data[c].exercise_price, 3, 0)))
                                _this.tableContentData[17].data.unshift(_this.$$.kilobitNumber(_this.$$.formatNumber(data[c].conv_ratio, 5, 0)) + ' : 1')
                                _this.tableContentData[18].data.unshift(data[c].underlying_curr + ' ' + _this.$$.formatNumber(data[c].BID, 2, 0))
                                _this.tableContentData[19].data.unshift(_this.$$.changeRate(_this.$$.formatNumber(data[c].BID_PCTCHNG, 2, 0)) + ' %')
                                _this.tableContentData[20].data.unshift(_this.$$.formatNumber(data[c].BIDSIZE / 1000, 0, 1))
                                _this.tableContentData[21].data.unshift(data[c].underlying_curr + ' ' + _this.$$.formatNumber(data[c].ASK, 2, 0))
                                // _this.tableContentData[22].data.unshift('-')
                                _this.tableContentData[22].data.unshift(_this.$$.formatNumber(data[c].ASKSIZE / 1000, 0, 1))
                                _this.tableContentData[23].data.unshift(_this.$$.formatNumber(data[c].tradeVolume / 1000, 0, 1))
                            } else {
                                _this.tableContentData[0].data.push([data[c].dsply_nmll, data[c].ric])
                                _this.tableContentData[1].data.push(arrIcon)
                                _this.tableContentData[2].data.push(data[c].issuer_name2)
                                _this.tableContentData[3].data.push(data[c].type)
                                _this.tableContentData[4].data.push(data[c].TimeToMaturity + ' ' + _this.$t("WSe.da"))
                                _this.tableContentData[5].data.push(data[c].LSTTRDDATE)
                                _this.tableContentData[6].data.push(_this.$$.formatNumber(data[c].sensitivity, 1, 1) > 1 ? _this.$$.formatNumber(data[c].sensitivity, 1, 1) + _this.$t("WTs.ticks") : _this.$$.formatNumber(data[c].sensitivity, 1, 1) + _this.$t("WTs.tick"))
                                _this.tableContentData[7].data.push(_this.$$.formatNumber(data[c].effective_gearing, 1, 1) + ' x')
                                _this.tableContentData[8].data.push(_this.$$.formatNumber(data[c].theta_days, 6, 1))
                                _this.tableContentData[9].data.push(_this.$$.formatNumber(data[c].theta_pct, 1, 1) + ' %')
                                _this.tableContentData[10].data.push(_this.$$.formatNumber(data[c].theta, 0, 1) > 1 ? _this.$$.formatNumber(data[c].theta, 0, 1) + ' ' + _this.$t("WSe.da") : _this.$$.formatNumber(data[c].theta, 0, 1) + ' ' + _this.$t("WSe.day"))
                                _this.tableContentData[11].data.push(`${_this.$$.formatNumber(data[c].moneyness_c.split('%')[0], 1, 0)}% ${data[c].moneyness_c.split('%')[1]}`)
                                _this.tableContentData[11].ifData.push(data[c].moneyness_c.indexOf('ITM') >= 0 ? true : false)
                                _this.tableContentData[12].data.push(_this.$$.formatNumber(data[c].premium, 1, 1) + ' %')
                                _this.tableContentData[13].data.push(_this.$$.formatNumber(data[c].delta, 1, 1) + ' %')
                                _this.tableContentData[14].data.push(_this.$$.formatNumber(data[c].implied_volatility, 1, 1) + ' %')
                                _this.tableContentData[15].data.push(_this.$$.formatNumber(data[c].underlying_hst_vol, 1, 1) + ' %')
                                _this.tableContentData[16].data.push(data[c].underlying_curr + ' ' + _this.$$.kilobitNumber(_this.$$.formatNumber(data[c].exercise_price, 3, 0)))
                                _this.tableContentData[17].data.push(_this.$$.kilobitNumber(_this.$$.formatNumber(data[c].conv_ratio, 5, 0)) + ' : 1')
                                _this.tableContentData[18].data.push(data[c].underlying_curr + ' ' + _this.$$.formatNumber(data[c].BID, 2, 0))
                                _this.tableContentData[19].data.push(_this.$$.changeRate(_this.$$.formatNumber(data[c].BID_PCTCHNG, 2, 0)) + ' %')
                                _this.tableContentData[20].data.push(_this.$$.formatNumber(data[c].BIDSIZE / 1000, 0, 1))
                                _this.tableContentData[21].data.push(data[c].underlying_curr + ' ' + _this.$$.formatNumber(data[c].ASK, 2, 0))
                                // _this.tableContentData[22].data.push(_this.$$.formatNumber('-'))
                                _this.tableContentData[22].data.push(_this.$$.formatNumber(data[c].ASKSIZE / 1000, 0, 1))
                                _this.tableContentData[23].data.push(_this.$$.formatNumber(data[c].tradeVolume / 1000, 0, 1))

                            }
                        })
                        if (data.last_update) {
                            // this.publishTime = this.$$.changeToLocalTime1(data.last_update)
                        }
                        this.$forceUpdate()
                        this.WarrantNameFixation()
                        setTimeout(function () {
                            _this.$$.tableWidth($('#WarrantComparison').find('.scrollBar').find('div'), $('#WarrantComparison').find('.tableBox').find('.table'))
                        }, 10);
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        // 重置
        info: function () {
            let timer1
            this.GetMarketData()
            $('#info').attr('disabled', true)
            clearTimeout(timer1);
            //设置一次性定时器
            timer1 = setTimeout(function () {
                $('#info').attr('disabled', false)
            }, 5000);
            this.handleMouse(false)
        },
        // 总点击消失
        ComboBoxShow: function () {
            this.ifSelectUnderlying = false
        },
        // select underlying 下拉菜单的显示事件
        selectUnderlyingShow: function () {
            this.ifSelectUnderlying = !this.ifSelectUnderlying
        },
        // select underlying 下拉菜单 列表数据
        selectUnderlyingFun: function (value, index, name, e) {
            console.log(this.underlyingRicData);
        
            let _this = this
            this.selectUnderlyingData[index].visible = e.target.checked
            if (e.target.checked) {
                if (this.underlyingRicData.length <= 8) {
                    this.underlyingRicData.push(value)
                    this.textArr.push(name)
                }
                if (this.underlyingRicData.length == 8) {
                    $.each(this.selectUnderlyingData, function (i, c) {
                        if (!c.visible) {
                            c.disabled = true
                        }
                    })
                    // 模态框出现
                    $('#WarrantComparisonMatrix').modal('show')
                }
            } else {
                this.underlyingRicData.splice(this.underlyingRicData.indexOf(value), 1)
                this.textArr.splice(this.textArr.indexOf(name), 1)
                if (this.underlyingRicData.length !== 8) {
                    $.each(this.selectUnderlyingData, function (i, c) {
                        if (!c.visible) {
                            c.disabled = false
                        }
                    })
                }
            }
            this.GetMarketData()
            this.selectUnderlyingInputText = this.textArr.toString()
        },
        // 重置提示
        handleMouse: function (ifov) {
            this.mouse = ifov
        },
        /*下载table*/
        printTable: function () {
            this.export_table_ws(".tableBox", this.$$.export_name("warrant_comparison"));
        },
        export_table_ws: function (selector, name) {
            if (document.querySelectorAll(selector).length == 0) {
                alert('Selector "' + selector + '" not exists!');
                return false;
            }
            this.$$.export_csv(
                this.get_table_data_ws(selector),
                name
            );
        },
        get_table_data_ws: function (selector) {
            var data = "";
            for (var i = 0; i < $(selector).find("tr:visible").length; i++) {
                if (i !== 2) {
                    for (
                        var j = 0; j <
                        $(selector)
                            .find("tr:visible")
                            .eq(i)
                            .find("td:visible").length; j++
                    ) {
                        data += $(selector)
                            .find("tr:visible")
                            .eq(i)
                            .find("td:visible")
                            .eq(j)
                            .text()
                            .trim()
                            .split(",")
                            .join("");
                        if (
                            j + 1 !=
                            $(selector)
                                .find("tr:visible")
                                .eq(i)
                                .find("td:visible").length
                        ) {
                            data += ",";
                        }
                    }
                    data += "\n";
                }
            }
            return data;
        },
        /*打印table*/
        correlationPrintTbale: function () {
            $('#printBox').show()
            $(".refresh_hint").hide();
            $('#printBox>.header').append($('#WarrantComparison').find('.headerpart').clone())
            $('#printBox>.content').append($('#WarrantComparison').find('.mainContent>.scope').find('div').eq(0).clone())
            $('#printBox>.content').append($('#WarrantComparison').find('.tableBox').find('.table').clone())
            $('#printBox>.bottom').append($('#WarrantComparison').find('.bottom-disclaimer').clone())
            $('#contentBox').hide()
            this.ariseCombobox('#comboboxUnderlying')

            let arr = [];
            window.scrollTo(0, 0);
            disableScroll.on();

            WarrantComparisonhtml2canvas(this.$refs.printheader, {
                backgroundColor: '#ffffff',
                useCORS: true,
                height: this.$refs.printheader.scrollHeight,
                width: this.$refs.printheader.scrollWidth
            }).then((canvas) => {
                arr[0] = canvas.toDataURL("image/png")

                WarrantComparisonhtml2canvas(this.$refs.printcontent, {
                    backgroundColor: '#ffffff',
                    useCORS: true,
                    height: this.$refs.printcontent.scrollHeight,
                    width: this.$refs.printcontent.scrollWidth
                }).then((canvas) => {
                    arr[1] = canvas.toDataURL("image/png")

                    WarrantComparisonhtml2canvas(this.$refs.printbottom, {
                        backgroundColor: '#ffffff',
                        useCORS: true,
                        height: this.$refs.printbottom.scrollHeight,
                        width: this.$refs.printbottom.scrollWidth
                    }).then((canvas) => {
                        arr[2] = canvas.toDataURL("image/png")

                        var printstr = "";
                        printstr += '<img width="100%" src="' + arr[0] + '"/>';
                        printstr += '<img width="100%" src="' + arr[1] + '"/>';
                        printstr += '<img width="100%" src="' + arr[2] + '"/>';

                        WarrantComparisonprintJS({
                            printable: printstr,
                            type: 'raw-html',
                            onLoadingStart: function () {
                                $('#contentBox').show();
                                $('#printBox').hide();
                                $('#printBox').find('.content').empty();
                                $('#printBox').find('.header').empty();
                                $('#printBox').find('.bottom').empty();
                                disableScroll.off();
                            }
                        });
                    });
                });
            })
        },
        //  Warrant Name 固定
        WarrantNameFixation: function () {
            setTimeout(function () {
                $('#WarrantComparison').find('.tableBox').scroll(function () {
                    if ($(this).scrollLeft() > 0) {
                        $('.nheadTableBox').show()
                    } else {
                        $('.nheadTableBox').hide()
                    }
                    $('.nheadTableBox').css({
                        'top': $('.functionality').outerHeight(true) + $('.reminder').outerHeight(true) + $('.scrollBar').outerHeight(true) + 30
                    })
                    $('.nheadTableBox').width($('#WarrantComparison').find('.tableBox').find('.table').find('tbody').find('tr').eq(0).find('td').eq(0).outerWidth(true))
                })
            }, 10);
        },
        // 出现combobox
        ariseCombobox: function (id) {
            let _this = this
            this.$$.jqcombobox(id)
            $(id).combobox({
                select: function (event, ui) {
                    _this.$$.inputEmpty($(id).parent())
                    _this.textArr = []
                    _this.underlyingRicData = []
                    _this.selectUnderlyingInputText = ''
                    $.each(_this.tableContentData, function (i, c) {
                        c.data = []
                        if (i == 7) {
                            c.ifData = []
                        }
                    })
                    _this.ifSelectUnderlying = true
                    console.log(this.value);
                    localStorage.setItem("comparisonRic", this.value)
                    localStorage.setItem("comparisonName", ui.item.innerText)
                    _this.GetRICListByUnderlying(this.value)
                }
            });
            $(id).parent().find('input').attr('placeholder', this.$t('WCn.SU'))
            this.$$.inputEmpty($(id).parent())
        },
    }
};